import React, { Component } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const Tooltip = loadable(() => import('../../fc-components/main/components'), {
  resolveComponent: components => components.Tooltip
});

export default class Slug extends Component {
  constructor() {
    super();

    this.renderTitle = this.renderTitle.bind(this);
  }

  shouldComponentUpdate() {
    return this.props.canUpdate;
  }

  renderTitle(title) {
    let classes = ['slug'];
    const { advertorial, className } = this.props;

    if (advertorial) {
      classes.push('slug--advertorial');
    }

    if (className) {
      classes.push(` ${className}__slug`);
    }

    classes = classes.join(' ');

    if (className === 'tag-page' || className === 'tag-page-section') {
      if (advertorial) {
        let advertorialTitle = title;
        if (advertorialTitle.toLowerCase().indexOf('advertorial') > -1) {
          advertorialTitle = advertorialTitle.replace('_advertorial', '');
          advertorialTitle = advertorialTitle.replace('advertorial', '');
        }

        // Saw this was an issue, fixed it...
        advertorialTitle = advertorialTitle.replace('&amp;', '&');

        return <h2 className={classes}>{advertorialTitle}</h2>;
      }
      return <h2 className={classes}>{title}</h2>;
    }

    if (className === 'category-page') {
      return <h1 className={classes}>{title}</h1>;
    }

    return <h2 className={classes}>{title}</h2>;
  }

  render() {
    if (!this.props) {
      return null;
    }

    const { slugName, className, linkTo, archives, showTooltip } = this.props;
    const hasLink = linkTo?.length || false;
    const slugClasses = `slug-component${
      className ? ` ${className}__slug-component` : ''
    }`;
    let title = slugName.replace(/\*/g, '').replace(/_/g, ' ');

    if (hasLink) {
      title = (
        <a href={linkTo} className="slug__title-link">
          {title}
        </a>
      );
    }

    return (
      <div className={slugClasses}>
        {this.props.hasSponsoredContentLabel ? (
          <div className="advertorial__sponsored-content-label">
            Sponsored Content
          </div>
        ) : (
          ''
        )}
        {this.renderTitle(title)}
        {showTooltip && (
          <Tooltip text="Fast Company Premium provides subscribers with access to exclusive reporting and analysis on emerging trends in technology, business innovation, future of work, and design.">
            <img
              src="//assets.fastcompany.com/image/upload/v1687452110/fcweb/info_icon.svg"
              alt="fc more info"
            />
          </Tooltip>
        )}
        {archives.name && archives.url ? (
          <a href={archives.url} className="slug__archives">
            <span>{archives.name}</span>
          </a>
        ) : null}
      </div>
    );
  }
}

Slug.defaultProps = {
  slugName: '',
  className: '',
  linkTo: '',
  advertorial: false,
  hasSponsoredContentLabel: false,
  archives: {
    name: '',
    url: ''
  },
  canUpdate: false,
  showTooltip: false
};

Slug.propTypes = {
  slugName: PropTypes.string,
  className: PropTypes.string,
  linkTo: PropTypes.string,
  advertorial: PropTypes.bool,
  hasSponsoredContentLabel: PropTypes.bool,
  archives: PropTypes.shape({}),
  canUpdate: PropTypes.bool,
  showTooltip: PropTypes.bool
};
